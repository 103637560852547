import { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
/** MUI Imports */
import {
  Link as MuiLink,
  Grid as MuiGrid,
  Table as MuiTable,
  Radio as MuiRadio,
  Button as MuiButton,
  Dialog as MuiDialog,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  TextField as MuiTextField,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
  RadioGroup as MuiRadioGroup,
  DialogTitle as MuiDialogTitle,
  ToggleButton as MuiToggleButton,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  InputAdornment as MuiInputAdornment,
  FormControlLabel as MuiFormControlLabel,
  ToggleButtonGroup as MuiToggleButtonGroup,
} from "@mui/material";
import {
  Help as MuiHelpIcon,
  Info as MuiInfoIcon,
  Warning as MuiWarningIcon,
  AddCircle as MuiAddCircleIcon,
} from "@mui/icons-material";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider as MuiLocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDateFns as MuiAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Alert, Theme, useAlerts } from "common";
/** Service Import */
import {
  saveLeaveAction,
  updateLeaveAction,
  getLeaveDetails,
  getLeaveInfoDetails,
  checkCrossSchoolAppointment,
  getAverageFTELast3Years,
  getLeaveEmailNotification,
  fetchLeaveValidation,
} from "app/services/leaveActionService";
/**Custom Component */
import { FormattedNumber } from "app/shared/ui/FormattedNumber";
import { EmailNotification } from "./EmailNotification";
import {
  DefaultDataSource,
  LeaveActions,
  LeaveActionsType,
  SegmentsColor,
} from "app/shared/constants";
import {
  convertDateObjToFLADateStr,
  numberToPercentage,
} from "app/shared/utils";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { DraggablePaper } from "app/shared/ui/DraggablePaper";

export const CreateEditViewLeaveActions = (props) => {
  const { t } = useTranslation();
  // To show the error
  const { alert, setAlert, clearAlert } = useAlerts();
  const {
    universityId,
    setReloadLeave,
    setReloadAudit,
    leaveId,
    actionType,
    setShowEditLeaveActionDialog,
    showEditLeaveActionDialog,
    setReloadAccrual,
  } = props;
  const leaveActionFormLabel = "LeaveAccruedTaken.leaveActionForm";
  const [actionId, setActionId] = useState(leaveId);
  // State for Dialog
  const [openDialog, setOpenDialog] = useState(false);
  // State for Save confirmation message Dialog
  const [showSaveConfirmationMsg, setShowSaveConfirmationMsg] = useState(false);
  // State for Cancel confirmation message Dialog
  const [showCancelConfirmationMsg, setShowCancelConfirmationMsg] =
    useState(false);

  // State for email notification Dialog
  const [showEmailNotificationDialog, setShowEmailNotificationDialog] =
    useState(false);

  const [validationWarning, setValidationWarning] = useState("");

  // Events to handle the open and close state of Dialog
  const handleClickOpen = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };
  //State to indicate show warning icon
  const [hasCrossSchoolAppointment, setHasCrossSchoolAppointment] = useState();
  // State for Leave Details
  const [leaveDetails, setLeaveDetails] = useState({});
  /** Call to service to fetch Leave Details */
  useEffect(() => {
    if (showEditLeaveActionDialog) {
      setActionId(leaveId);
      setLeaveDetails({});
      getLeaveDetails(
        setLeaveDetails,
        setHasCrossSchoolAppointment,
        setAlert,
        universityId,
        leaveId
      );
    }
    setDirtyFlag(false);
    if (!openDialog || !showEditLeaveActionDialog) {
      clearAlert();
    }
  }, [
    showEditLeaveActionDialog,
    universityId,
    leaveId,
    openDialog,
    clearAlert,
    setAlert,
  ]);

  const disabledFields = actionType === "view";
  const inputVariantValue = (actionType === "view" && "standard") || "outlined";
  // Initial Form Values
  const leaveActionFormValues = {
    type: leaveDetails.type || t("globals.labels.categorySAB"),
    currentState:
      t(
        LeaveActions[
          leaveDetails.currentState && leaveDetails.currentState.value
        ]
      ) || t(LeaveActions["DRAFT"]),
    actionState:
      (leaveDetails.currentState && leaveDetails.currentState.value) ||
      "DEPT_OAA_REVIEW",
    nextStates: leaveDetails["nextStates"] || [],
    applicationDocumentUrl: leaveDetails.applicationDocumentUrl || "",
    startDate: leaveDetails.startDate || null,
    endDate: leaveDetails.endDate || null,
    percentageOnDuty: leaveDetails.percentageOnDuty,
    note: leaveDetails.leaveNotes || "",
    notificationRemarks: leaveDetails.notificationRemarks || "",
    leavePercentageIncrease: leaveDetails.leavePercentageIncrease,
    dataSource: DefaultDataSource,
  };
  // **** Form Validation Schema ****
  const LeaveActionFormValidation = Yup.object().shape({
    startDate: Yup.date().required("Required"),
    endDate: Yup.date().required("Required"),
    percentageOnDuty: Yup.number().min(0).max(99.999).required("Required"),
    leavePercentageIncrease: Yup.number().min(0).max(99.999),
  });

  // State to indicate Application Document value is formatted URL or not
  const [isURLValidate, setIsURLValidate] = useState(false);
  // State to indicate show info details
  const [showInfoDetails, setShowInfoDetails] = useState(false);
  //*** To validate the URL format ***
  const checkURLFormat = (fieldValue) => {
    let resultValue = fieldValue.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    );
    resultValue == null ? setIsURLValidate(true) : setIsURLValidate(false);
  };

  // *** Check if the Manual Typed Dates are valid ***
  const checkIfValidDates = (startDate, endDate) => {
    return startDate && endDate
      ? endDate.getTime() < startDate.getTime()
      : false;
  };
  // State to indicate Percentage On Duty value is in required format or not
  const [isPercentageOnDutyValid, setIsPercentageOnDutyValid] = useState(true);

  const [isPercentageIncreaseValid, setIsPercentageIncreaseValid] =
    useState(true);

  // *** Check format of Percentage on Duty***
  const checkPercentageFormat = (value, setterFunction) => {
    let splitValue = value.split(".")[1];
    !splitValue || (splitValue && splitValue.length <= 3)
      ? setterFunction(true)
      : setterFunction(false);
  };
  // *** Flag to identify user has made any changes in form or not ***
  const [dirtyFlag, setDirtyFlag] = useState(false);

  const [leaveInfoDetails, setLeaveInfoDetails] = useState({});
  //** Click handler of Info Icon */
  const handleInfoIconClick = () => {
    getLeaveInfoDetails(setLeaveInfoDetails, setAlert, universityId, actionId);
    setShowInfoDetails(!showInfoDetails);
  };

  const [emailDetails, setEmailDetails] = useState({});

  const [fetchGetEmailNotification, setFetchGetEmailNotification] =
    useState(false);

  //Handler function to save/update leave details
  const handleFormSubmit = (values) => {
    (actionType === "add" &&
      saveLeaveAction(
        setShowSaveConfirmationMsg,
        setOpenDialog,
        setReloadAudit,
        setAlert,
        setActionId,
        setFetchGetEmailNotification,
        { values, universityId },
        t
      ) &&
      setHasCrossSchoolAppointment(false)) ||
      (actionType === "edit" &&
        updateLeaveAction(
          setShowSaveConfirmationMsg,
          setShowEditLeaveActionDialog,
          setShowInfoDetails,
          setReloadAudit,
          setReloadAccrual,
          setAlert,
          setActionId,
          setFetchGetEmailNotification,
          { values, universityId, actionId },
          t
        ));
  };

  useEffect(() => {
    fetchGetEmailNotification &&
      getLeaveEmailNotification(
        universityId,
        actionId,
        setShowEmailNotificationDialog,
        setAlert,
        setEmailDetails,
        setReloadLeave,
        setFetchGetEmailNotification
      );
  }, [
    actionId,
    actionType,
    fetchGetEmailNotification,
    setReloadLeave,
    universityId,
    setAlert,
  ]);

  const showWarningIconValidation = (startDate, endDate, type) => {
    if (startDate && endDate && type) {
      checkCrossSchoolAppointment(
        setHasCrossSchoolAppointment,
        universityId,
        startDate,
        endDate,
        type
      );
    } else {
      setHasCrossSchoolAppointment(false);
    }
  };

  const handleClose = (event, reason) => {
    setHasCrossSchoolAppointment(false);

    if (reason === "escapeKeyDown") {
      actionType === "add" && setOpenDialog(false);
      actionType !== "add" && setShowEditLeaveActionDialog(false);
      if (dirtyFlag) {
        setOpenDialog(true);
      }
      event.stopPropagation();
      actionType !== "view" && setShowCancelConfirmationMsg(dirtyFlag);
    }
  };

  const saveClicked = (values) => {
    const leave = {
      startDate: values.startDate,
      endDate: values.endDate,
      type: values.type,
      // only send actionId if editing leave. Somehow this actionId persists from the last time a new leave got added/edited
      actionId: actionType === "add" ? null : actionId,
      percentageOnDuty: values.percentageOnDuty,
      leavePercentageIncrease: values.leavePercentageIncrease,
      dataSource: DefaultDataSource,
    };

    fetchLeaveValidation(
      universityId,
      leave,
      codeToMessage,
      setShowSaveConfirmationMsg,
      showCreateLeaveError,
      setValidationWarning
    );
  };

  const showCreateLeaveError = (error) =>
    setAlert("error", error, false, false);

  const codeToMessage = (messageObj) => {
    if (messageObj.code === "OVRLP_FL") {
      return t(
        `LeaveAccruedTaken.leaveActionForm.leaveValidationMessage.${messageObj.code}`,
        {
          startDate: messageObj.value.startDate,
          endData: messageObj.value.endDate,
          percentageOnDuty: messageObj.value.percentageOnDuty,
        }
      );
    } else {
      return t(
        `LeaveAccruedTaken.leaveActionForm.leaveValidationMessage.${messageObj.code}`
      );
    }
  };

  const handleCloseEmailNotification = (event) => {
    setShowEmailNotificationDialog(false);
    setReloadLeave(true);
  };
  // State to store average FTP for last 3 years.
  const [averageFTELast3Years, setAverageFTELast3Years] = useState({});
  // *** Function called when startDate updated, will update average FTP ***
  const updateAverageFTELast3Years = useCallback(
    (startDate, type) => {
      if (startDate && type === LeaveActionsType.PDL) {
        getAverageFTELast3Years(
          setAverageFTELast3Years,
          setAlert,
          universityId,
          startDate
        );
      } else {
        setAverageFTELast3Years({});
      }
    },
    [setAlert, universityId]
  );

  const isAverageFTEExists = (type) => {
    if (
      type === LeaveActionsType.PDL &&
      Math.round(averageFTELast3Years.averageFte * 100 + "e+3") + "e-3" < 100
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    updateAverageFTELast3Years(
      leaveActionFormValues.startDate,
      leaveActionFormValues.type
    );
  }, [
    leaveActionFormValues.startDate,
    leaveActionFormValues.type,
    updateAverageFTELast3Years,
  ]);

  // Custom styles for the radio button
  const radioButtonStyles = {
    marginRight: "30px",
    "& .MuiRadio-root": {
      padding: "4px",
    },
    // Show the Radio button label black even when disabled
    "& .MuiFormControlLabel-label": {
      color: Theme.palette.common.black + " !important",
    },
  };

  const buttonsCount =
    (leaveDetails.nextStates && leaveDetails.nextStates.length) || 1;

  // Custom styles for the toggle button group
  const toggleButtonGroupStyles = {
    display: "flex",
    "& .MuiToggleButton-root": {
      width: (buttonsCount < 4 && "29%") || "42%",
      color: Theme.palette.common.black,
      textTransform: "none",
      "&:last-of-type ": {
        borderTopLeftRadius: (buttonsCount === 1 && "8px") || "0px",
        borderBottomLeftRadius: (buttonsCount === 1 && "8px") || "0px",
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
      },
      "&:first-of-type ": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
      },
    },
  };

  // Custom styles for the table cell
  const tableCellStyles = {
    border: "1px solid",
    borderColor: Theme.palette.grey[400],
  };

  // Custom styles for the disabled text fields
  const disabledFieldStyles = {
    // Keep the text color black even when disabled
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "black",
    },
    // Ensure solid bottom border when disabled
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "solid", // Ensure solid bottom border when disabled
    },
  };

  return (
    <>
      {actionType === "add" && (
        <MuiButton
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
        >
          <MuiAddCircleIcon fontSize="small" />
          &nbsp;&nbsp;{" "}
          {t(
            "LeaveAccruedTaken.leaveHistoryLabels.createLeaveActionButtonLabel"
          )}
        </MuiButton>
      )}
      <MuiDialog
        open={showEditLeaveActionDialog || openDialog}
        PaperComponent={DraggablePaper}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        onClick={(event) => {
          event.stopPropagation();
        }}
        onClose={handleClose}
        hideBackdrop
        disableScrollLock
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxHeight: "100%",
              maxWidth: "720px",
            },
          },
        }}
      >
        <MuiDialogTitle
          id="draggable-dialog-title"
          sx={{ cursor: "move", paddingBottom: "0px" }}
        >
          <MuiGrid container justifyContent="space-between">
            <MuiGrid item>
              {(actionType === "add" &&
                t(`${leaveActionFormLabel}.newLeaveActionTitle`)) ||
                (actionType === "edit" &&
                  t(`${leaveActionFormLabel}.editLeaveActionTitle`)) ||
                t(`${leaveActionFormLabel}.viewLeaveActionTitle`)}
            </MuiGrid>
            {hasCrossSchoolAppointment && (
              <MuiGrid>
                <MuiTooltip
                  title={t(`${leaveActionFormLabel}.tooltipText`)}
                  placement="right"
                >
                  <MuiWarningIcon
                    fontSize="large"
                    sx={{ color: Theme.palette.warning.main, marginTop: "5px" }}
                  />
                </MuiTooltip>
              </MuiGrid>
            )}
          </MuiGrid>
        </MuiDialogTitle>
        <MuiDialogContent sx={{ padding: "0px 24px 16px 26px" }}>
          <Formik
            initialValues={leaveActionFormValues}
            enableReinitialize
            validationSchema={LeaveActionFormValidation}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              handleFormSubmit(values);
            }}
          >
            {(props) => {
              const {
                values,
                dirty,
                isValid,
                errors,
                handleChange,
                handleSubmit,
                handleBlur,
                touched,
              } = props;
              return (
                /** Set a flag on change of form values,
                 * so to access that outside the form to show the
                 * 'discard of changes' message
                 **/
                <form
                  onSubmit={handleSubmit}
                  onChange={(event) =>
                    setDirtyFlag(event.target.value ? true : false)
                  }
                >
                  {alert.exists && <Alert />}
                  <MuiRadioGroup
                    aria-label="radioGroup"
                    name="type"
                    value={values.type}
                    onChange={(event) => {
                      showWarningIconValidation(
                        values.startDate,
                        values.endDate,
                        event.target.value
                      );
                      handleChange(event);
                      updateAverageFTELast3Years(
                        values.startDate,
                        event.target.value
                      );
                    }}
                    row
                  >
                    <MuiFormControlLabel
                      value={LeaveActionsType.SAB}
                      control={
                        <MuiRadio
                          disabled={actionType === "edit" || disabledFields}
                        />
                      }
                      label={t(
                        "LeaveAccruedTaken.appointmentSegmentsLabels.SAB"
                      )}
                      sx={{
                        "& .Mui-checked": {
                          color: SegmentsColor.SAB_LIGHT + "!important",
                        },
                        ...radioButtonStyles,
                      }}
                    />
                    <MuiFormControlLabel
                      value={LeaveActionsType.PDL}
                      control={
                        <MuiRadio
                          disabled={actionType === "edit" || disabledFields}
                        />
                      }
                      label={t("LeaveAccruedTaken.accrualLabels.pdl")}
                      sx={{
                        "& .Mui-checked": {
                          color: SegmentsColor.PDL_LIGHT,
                        },
                        ...radioButtonStyles,
                      }}
                    />
                    <MuiFormControlLabel
                      value={LeaveActionsType.LWOS}
                      control={
                        <MuiRadio
                          disabled={actionType === "edit" || disabledFields}
                        />
                      }
                      label={t("LeaveAccruedTaken.accrualLabels.lwos")}
                      sx={{
                        "& .Mui-checked": {
                          color: SegmentsColor.DEFAULT,
                        },
                        ...radioButtonStyles,
                      }}
                    />
                    <MuiFormControlLabel
                      value={LeaveActionsType.LOA}
                      control={
                        <MuiRadio
                          disabled={actionType === "edit" || disabledFields}
                        />
                      }
                      label={t("LeaveAccruedTaken.accrualLabels.loa")}
                      sx={{
                        "& .Mui-checked": {
                          color: SegmentsColor.DEFAULT,
                        },
                        ...radioButtonStyles,
                      }}
                    />
                    <MuiTypography variant="body1" sx={{ margin: "3px auto" }}>
                      {values.type === LeaveActionsType.PDL &&
                        values.startDate &&
                        t(`${leaveActionFormLabel}.averageFTELast3Years`, {
                          percentageFTE: averageFTELast3Years.averageFte
                            ? numberToPercentage(
                                averageFTELast3Years.averageFte
                              )
                            : 0,
                        })}
                    </MuiTypography>
                  </MuiRadioGroup>
                  <MuiGrid container direction="column" spacing={2}>
                    <MuiGrid item container>
                      <MuiGrid item container wrap="nowrap" spacing={1}>
                        <MuiGrid
                          item
                          xs={
                            (isAverageFTEExists(values.type) &&
                              actionType === "edit" &&
                              1) ||
                            2
                          }
                        >
                          <MuiTypography variant="subtitle1">
                            {t(
                              `${leaveActionFormLabel}.stateLabels.currentState`
                            )}
                          </MuiTypography>
                        </MuiGrid>
                        <MuiGrid
                          item
                          container
                          spacing={1}
                          sx={{ marginLeft: "12px" }}
                        >
                          <MuiGrid
                            item
                            xs={
                              isAverageFTEExists(values.type) &&
                              actionType === "edit" &&
                              1
                            }
                          >
                            <MuiTypography variant="subtitle1">
                              {t(
                                `${leaveActionFormLabel}.stateLabels.nextState`
                              )}
                            </MuiTypography>
                          </MuiGrid>
                          <MuiGrid item>
                            <MuiLink
                              href={
                                process.env.PUBLIC_URL +
                                "/documents/Leave Action State Transitions.pdf"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <MuiHelpIcon color="primary" fontSize="small" />
                            </MuiLink>
                          </MuiGrid>
                        </MuiGrid>
                      </MuiGrid>
                      <MuiGrid item container wrap="nowrap">
                        <MuiGrid
                          item
                          xs={(isAverageFTEExists(values.type) && 8) || 12}
                          container
                          spacing={1}
                        >
                          <MuiGrid
                            item
                            xs={
                              (isAverageFTEExists(values.type) &&
                                actionType === "add" &&
                                3) ||
                              2
                            }
                          >
                            <MuiToggleButton
                              size="small"
                              value={values.currentState}
                              disabled
                              selected
                              sx={{
                                borderRadius: "8px",
                                width: "98%",
                                textTransform: "none",
                              }}
                            >
                              {values.currentState}
                            </MuiToggleButton>
                          </MuiGrid>
                          <MuiGrid item xs={9}>
                            <MuiToggleButtonGroup
                              size="small"
                              exclusive
                              id="actionState"
                              aria-label="leave action state"
                              value={values.actionState}
                              sx={toggleButtonGroupStyles}
                              onChange={(event, value) => {
                                props.setFieldValue("actionState", value);
                              }}
                            >
                              {actionType === "add" && (
                                <MuiToggleButton
                                  value={values.actionState}
                                  disabled
                                >
                                  {t(LeaveActions[values.actionState])}
                                </MuiToggleButton>
                              )}
                              {actionType !== "add" &&
                                values.nextStates.map((stateValue, index) => {
                                  return (
                                    <MuiToggleButton
                                      key={index}
                                      value={stateValue.value}
                                      disabled={disabledFields}
                                    >
                                      {t(LeaveActions[stateValue.value])}
                                    </MuiToggleButton>
                                  );
                                })}
                            </MuiToggleButtonGroup>
                          </MuiGrid>
                        </MuiGrid>
                        {isAverageFTEExists(values.type) &&
                          values.startDate && (
                            <MuiGrid item xs={4} container>
                              <MuiGrid item xs={11}>
                                <NumericFormat
                                  id="leavePercentageIncrease"
                                  label={`${t(
                                    `${leaveActionFormLabel}.leavePercentageIncreaseLabel`
                                  )}*`}
                                  value={values.leavePercentageIncrease}
                                  error={
                                    (touched.leavePercentageIncrease &&
                                      !isPercentageIncreaseValid) ||
                                    (touched.leavePercentageIncrease &&
                                      errors.leavePercentageIncrease)
                                  }
                                  helperText={
                                    (touched.leavePercentageIncrease &&
                                      !isPercentageIncreaseValid) ||
                                    (touched.leavePercentageIncrease &&
                                      errors.leavePercentageIncrease)
                                      ? t(
                                          `${leaveActionFormLabel}.messages.percentageOnLeaveErrorMessage`
                                        )
                                      : ""
                                  }
                                  customInput={MuiTextField}
                                  variant={
                                    (actionType === "edit" && "outlined") ||
                                    inputVariantValue
                                  }
                                  autoComplete="off"
                                  decimalScale={3}
                                  onChange={(event, value) => {
                                    props.setFieldValue(
                                      "leavePercentageIncrease",
                                      event.target.value
                                    );
                                    checkPercentageFormat(
                                      event.target.value,
                                      setIsPercentageIncreaseValid
                                    );
                                  }}
                                  sx={{
                                    // Allow label to wrap
                                    "& .MuiFormLabel-root": {
                                      lineHeight: 1,
                                      whiteSpace: "normal",
                                    },
                                    ...disabledFieldStyles,
                                  }}
                                  onBlur={handleBlur}
                                  allowNegative={false}
                                  InputProps={{
                                    endAdornment: (
                                      <MuiInputAdornment position="end">
                                        %
                                      </MuiInputAdornment>
                                    ),
                                  }}
                                  disabled={
                                    (actionType === "edit" &&
                                      !leaveDetails.dateEditable) ||
                                    disabledFields
                                  }
                                />
                              </MuiGrid>
                              <MuiGrid item xs={1} sx={{ alignSelf: "center" }}>
                                <MuiTooltip
                                  title={t(
                                    `${leaveActionFormLabel}.leavePercentageIncreaseTooltipText`
                                  )}
                                >
                                  <MuiHelpIcon
                                    color="primary"
                                    fontSize="small"
                                  />
                                </MuiTooltip>
                              </MuiGrid>
                            </MuiGrid>
                          )}
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid item>
                      <MuiTextField
                        id="applicationDocumentUrl"
                        label={t(
                          `${leaveActionFormLabel}.applicationDocumentFieldLabel`
                        )}
                        onChange={(event) => {
                          checkURLFormat(event.target.value);
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        fullWidth
                        autoComplete="off"
                        inputProps={{ maxLength: 250 }}
                        value={values.applicationDocumentUrl}
                        error={touched.applicationDocumentUrl && isURLValidate}
                        helperText={
                          touched.applicationDocumentUrl && isURLValidate
                            ? t(
                                `${leaveActionFormLabel}.messages.applicationURLValidationMsg`
                              )
                            : ""
                        }
                        disabled={
                          (actionType === "edit" &&
                            !leaveDetails.urlEditable) ||
                          disabledFields
                        }
                        variant={
                          (actionType === "edit" &&
                            leaveDetails.urlEditable &&
                            "outlined") ||
                          inputVariantValue
                        }
                        sx={disabledFieldStyles}
                      />
                    </MuiGrid>
                    <MuiGrid container item>
                      <MuiGrid item xs={4}>
                        <MuiGrid item xs={11}>
                          <MuiLocalizationProvider
                            dateAdapter={MuiAdapterDateFns}
                          >
                            {actionType === "view" ? (
                              <MuiTextField
                                label={`${t(
                                  `${leaveActionFormLabel}.startDateLabel`
                                )}*`}
                                size="small"
                                value={convertDateObjToFLADateStr(
                                  values.startDate
                                )}
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                sx={disabledFieldStyles}
                                disabled
                              />
                            ) : (
                              <MuiDatePicker
                                label={`${t(
                                  `${leaveActionFormLabel}.startDateLabel`
                                )}*`}
                                name="startDate"
                                value={values.startDate}
                                format="yyyy-MM-dd"
                                id="date-picker-inline-start-from"
                                onChange={(newValue) => {
                                  props.setFieldValue("startDate", newValue);
                                  !checkIfValidDates(
                                    newValue,
                                    values.endDate
                                  ) &&
                                    showWarningIconValidation(
                                      newValue,
                                      values.endDate,
                                      values.type
                                    );
                                  updateAverageFTELast3Years(
                                    newValue,
                                    values.type
                                  );
                                }}
                                onBlur={handleBlur}
                                maxDateMessage={`${t(
                                  "globals.form.dateValidationMessages.startDateValidation"
                                )}`}
                                maxDate={values.endDate}
                                disabled={
                                  (actionType === "edit" &&
                                    !leaveDetails.dateEditable) ||
                                  disabledFields
                                }
                                sx={{
                                  // Keep the text color black when disabled
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                }}
                              />
                            )}
                          </MuiLocalizationProvider>
                        </MuiGrid>
                      </MuiGrid>
                      <MuiGrid item xs={4}>
                        <MuiGrid item xs={11}>
                          <MuiLocalizationProvider
                            dateAdapter={MuiAdapterDateFns}
                          >
                            {actionType === "view" ? (
                              <MuiTextField
                                label={`${t(
                                  `${leaveActionFormLabel}.endDateLabel`
                                )}*`}
                                size="small"
                                value={convertDateObjToFLADateStr(
                                  values.endDate
                                )}
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                sx={disabledFieldStyles}
                                disabled
                              />
                            ) : (
                              <MuiDatePicker
                                label={`${t(
                                  `${leaveActionFormLabel}.endDateLabel`
                                )}*`}
                                name="endDate"
                                value={values.endDate}
                                format="yyyy-MM-dd"
                                id="date-picker-inline-start-from"
                                onChange={(newValue) => {
                                  props.setFieldValue("endDate", newValue);
                                  !checkIfValidDates(
                                    values.startDate,
                                    newValue
                                  ) &&
                                    showWarningIconValidation(
                                      values.startDate,
                                      newValue,
                                      values.type
                                    );
                                }}
                                onBlur={handleBlur}
                                minDateMessage={`${t(
                                  "globals.form.dateValidationMessages.endDateValidation"
                                )}`}
                                minDate={values.startDate}
                                disabled={
                                  (actionType === "edit" &&
                                    !leaveDetails.dateEditable) ||
                                  disabledFields
                                }
                                sx={{
                                  // Keep the text color black when disabled
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                }}
                              />
                            )}
                          </MuiLocalizationProvider>
                        </MuiGrid>
                      </MuiGrid>
                      <MuiGrid item xs={4}>
                        <NumericFormat
                          id="percentageOnDuty"
                          label={`${t(
                            `${leaveActionFormLabel}.percentageOnDutyLabel`
                          )}*`}
                          value={values.percentageOnDuty}
                          error={
                            (touched.percentageOnDuty &&
                              !isPercentageOnDutyValid) ||
                            (touched.percentageOnDuty &&
                              errors.percentageOnDuty)
                              ? true
                              : false
                          }
                          helperText={
                            (touched.percentageOnDuty &&
                              !isPercentageOnDutyValid) ||
                            (touched.percentageOnDuty &&
                              errors.percentageOnDuty)
                              ? t(
                                  `${leaveActionFormLabel}.messages.percentageOnLeaveErrorMessage`
                                )
                              : ""
                          }
                          customInput={MuiTextField}
                          variant={
                            (actionType === "edit" &&
                              leaveDetails.percentageEditable &&
                              "outlined") ||
                            inputVariantValue
                          }
                          autoComplete="off"
                          decimalScale={3}
                          onChange={(event, value) => {
                            props.setFieldValue(
                              "percentageOnDuty",
                              event.target.value
                            );
                            checkPercentageFormat(
                              event.target.value,
                              setIsPercentageOnDutyValid
                            );
                          }}
                          onBlur={handleBlur}
                          allowNegative={false}
                          fullWidth
                          sx={{
                            // Align the field with other fields
                            ".MuiInputBase-input.Mui-disabled": {
                              padding:
                                actionType === "view" ? "0px 0px 5px" : "",
                            },
                            ...disabledFieldStyles,
                          }}
                          InputProps={{
                            endAdornment: (
                              <MuiInputAdornment position="end">
                                %
                              </MuiInputAdornment>
                            ),
                          }}
                          disabled={
                            (actionType === "edit" &&
                              !leaveDetails.percentageEditable) ||
                            disabledFields
                          }
                        />
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid item>
                      <MuiTextField
                        id="note"
                        label={t(
                          `${leaveActionFormLabel}.leaveActionNotesFieldLabel`
                        )}
                        multiline
                        fullWidth
                        variant={
                          (actionType === "view" && "standard") || "outlined"
                        }
                        inputProps={{ maxLength: 2000 }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.note}
                        disabled={actionType === "view"}
                        sx={disabledFieldStyles}
                      />
                    </MuiGrid>
                    <MuiGrid item>
                      <MuiTextField
                        id="notificationRemarks"
                        label={t(
                          `${leaveActionFormLabel}.notificationRemarksFieldLabel`
                        )}
                        multiline
                        fullWidth
                        variant={
                          (actionType === "view" && "standard") || "outlined"
                        }
                        inputProps={{ maxLength: 2000 }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.notificationRemarks}
                        disabled={actionType === "view"}
                        sx={disabledFieldStyles}
                      />
                    </MuiGrid>
                  </MuiGrid>
                  <MuiDialogActions sx={{ padding: "16px 0px 0px" }}>
                    <MuiGrid container>
                      <MuiGrid item xs={4}>
                        {leaveDetails.infoAvailable && (
                          <MuiIconButton onClick={() => handleInfoIconClick()}>
                            <MuiInfoIcon color="primary" fontSize="small" />
                          </MuiIconButton>
                        )}
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      container
                      item
                      xs={8}
                      spacing={2}
                      justifyContent="flex-end"
                      wrap="nowrap"
                    >
                      {(actionType !== "view" && (
                        <>
                          <MuiGrid container item xs={8}>
                            <MuiButton
                              onClick={() => {
                                dirty
                                  ? setShowCancelConfirmationMsg(true)
                                  : (setOpenDialog(false) ||
                                      (setShowEditLeaveActionDialog &&
                                        setShowEditLeaveActionDialog(false)) ||
                                      setShowInfoDetails(false)) &&
                                    setHasCrossSchoolAppointment(false);
                              }}
                              fullWidth
                              variant="outlined"
                              color="primary"
                            >
                              {t("globals.form.actionButtons.cancel")}
                            </MuiButton>
                            <ConfirmationDialog
                              open={showCancelConfirmationMsg}
                              message={t(
                                `${leaveActionFormLabel}.messages.cancelConfirmationMessage`
                              )}
                              handleCancel={() => {
                                setShowCancelConfirmationMsg(false);
                              }}
                              handleOk={() => {
                                setShowCancelConfirmationMsg(false);
                                setHasCrossSchoolAppointment(false);
                                setOpenDialog(false);
                                setShowEditLeaveActionDialog &&
                                  setShowEditLeaveActionDialog(false);
                                setShowInfoDetails(false);
                              }}
                              okLabel={t("globals.form.actionButtons.yes")}
                              cancelLabel={t("globals.form.actionButtons.no")}
                            />
                          </MuiGrid>
                          <MuiGrid item container xs={8}>
                            <MuiButton
                              fullWidth
                              variant="contained"
                              onClick={() => saveClicked(values)}
                              color="primary"
                              disabled={
                                !dirty ||
                                !isValid ||
                                checkIfValidDates(
                                  values.startDate,
                                  values.endDate
                                )
                              }
                            >
                              {t("globals.form.actionButtons.save")}
                            </MuiButton>
                            <SpeedBumpDialog
                              warning={validationWarning}
                              values={values}
                              open={showSaveConfirmationMsg}
                              handleCancel={() =>
                                setShowSaveConfirmationMsg(false)
                              }
                              handleOk={handleSubmit}
                            />
                          </MuiGrid>
                        </>
                      )) || (
                        <MuiGrid item container xs={6}>
                          <MuiButton
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              setShowEditLeaveActionDialog(false);
                              setShowInfoDetails(false);
                            }}
                            color="primary"
                          >
                            {t("globals.form.actionButtons.close")}
                          </MuiButton>
                        </MuiGrid>
                      )}
                    </MuiGrid>
                  </MuiDialogActions>
                  {leaveDetails.infoAvailable && showInfoDetails && (
                    <MuiGrid container spacing={1}>
                      <MuiGrid item xs={6}>
                        <MuiTable size="small">
                          <MuiTableBody>
                            <MuiTableRow></MuiTableRow>
                            <MuiTableRow>
                              <MuiTableCell
                                component="th"
                                scope="row"
                                sx={tableCellStyles}
                                width="60%"
                              >
                                {t(
                                  `${leaveActionFormLabel}.accrualBegBalanceLabel`
                                )}
                              </MuiTableCell>
                              <MuiTableCell
                                align="right"
                                sx={tableCellStyles}
                                width="40%"
                              >
                                {leaveInfoDetails.accruedBegBalance &&
                                  FormattedNumber(
                                    leaveInfoDetails.accruedBegBalance
                                  )}
                              </MuiTableCell>
                            </MuiTableRow>
                            <MuiTableRow>
                              <MuiTableCell
                                component="th"
                                scope="row"
                                sx={tableCellStyles}
                              >
                                {t(`${leaveActionFormLabel}.leaveTakenLabel`)}
                              </MuiTableCell>
                              <MuiTableCell align="right" sx={tableCellStyles}>
                                {leaveInfoDetails.leaveTaken &&
                                  FormattedNumber(leaveInfoDetails.leaveTaken)}
                              </MuiTableCell>
                            </MuiTableRow>
                            <MuiTableRow>
                              <MuiTableCell
                                component="th"
                                scope="row"
                                sx={tableCellStyles}
                              >
                                {t(
                                  `${leaveActionFormLabel}.accrualEndBalanceLabel`
                                )}
                              </MuiTableCell>
                              <MuiTableCell align="right" sx={tableCellStyles}>
                                {leaveInfoDetails.accruedEndBalance &&
                                  FormattedNumber(
                                    leaveInfoDetails.accruedEndBalance
                                  )}
                              </MuiTableCell>
                            </MuiTableRow>
                          </MuiTableBody>
                        </MuiTable>
                      </MuiGrid>
                      <MuiGrid
                        item
                        container
                        xs={6}
                        direction="column"
                        justifyContent="space-around"
                        sx={{
                          fontStyle: "italic",
                          color: Theme.palette.grey[700],
                        }}
                      >
                        <MuiGrid item>
                          {t(`${leaveActionFormLabel}.projectedStartDateLabel`)}
                        </MuiGrid>
                        <MuiGrid item>{/* empty row */}</MuiGrid>
                        <MuiGrid item>
                          {t(`${leaveActionFormLabel}.projectedEndDateLabel`)}
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                  )}
                </form>
              );
            }}
          </Formik>
        </MuiDialogContent>
      </MuiDialog>
      <EmailNotification
        open={showEmailNotificationDialog}
        onClose={handleCloseEmailNotification}
        emailDetails={emailDetails}
        setReloadLeave={setReloadLeave}
        universityId={universityId}
        actionId={actionId}
        setShowEmailNotificationDialog={setShowEmailNotificationDialog}
      />
    </>
  );
};

const SpeedBumpDialog = (props) => {
  const { t } = useTranslation();
  const { values, open, handleOk, handleCancel, warning } = props;

  const getEditSaveConfirmationWarning = () => {
    if (!warning) {
      // no warning -> don't show title
      return "";
    }

    return (
      <span>
        <MuiWarningIcon
          fontSize="large"
          style={{
            color: Theme.palette.warning.main,
            position: "relative",
            top: "7px",
          }}
        />
        &nbsp;{warning}
      </span>
    );
  };

  return (
    <ConfirmationDialog
      title={getEditSaveConfirmationWarning()}
      open={open}
      message={t(
        `LeaveAccruedTaken.leaveActionForm.messages.saveConfirmationMessage`,
        {
          category: values.type,
          nextState: t(LeaveActions[values.actionState]),
        }
      )}
      handleCancel={handleCancel}
      handleOk={handleOk}
      okLabel={t("globals.form.actionButtons.yes")}
      cancelLabel={t("globals.form.actionButtons.no")}
    />
  );
};
